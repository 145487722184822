<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Appointments/Exports/Customers</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Service</label>
                  <VueMultiselect v-model="selectedService" :options="serviceOptions" :allow-empty="false"
                                  :close-on-select="true" label="name" track-by="value" :show-labels="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>State</label>
                  <VueMultiselect v-model="selectedState" class="" :options="stateOptions" :close-on-select="true"
                                  label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Preference</label>
                  <VueMultiselect v-model="selectedPreference" class="" :options="preferenceOptions"
                                  :close-on-select="true"
                                  label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Appointment Type</label>
                  <VueMultiselect v-model="selectedAppointmentType" class="" :options="appointmentTypeOptions"
                                  :allow-empty="false" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <div class="form-group">
                  <label style="visibility: hidden">Customer Or Address Search</label>
                  <input v-model="customerOrAddressSearch"
                         class="form-control " type="text" placeholder="Search by Customer or address">
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Status</label>
                  <VueMultiselect v-model="selectedStatus" class="" :options="appointmentStatusOptions"
                                  :allow-empty="false" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label style="visibility: hidden">Filter & Preview</label>
                  <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="applyAppointmentFilter(null)">Filter & Preview
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <h3>Export Columns</h3>
            <div class="row align-items-center ">
              <div class="col-6 col-sm-3 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="firstNameCheckbox" value="first_name">
                  <label class="form-check-label" for="firstNameCheckbox">First Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="lastNameCheckbox" value="last_name">
                  <label class="form-check-label" for="lastNameCheckbox">Last Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="fullNameCheckbox" value="full_name">
                  <label class="form-check-label" for="fullNameCheckbox">Full Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="emailCheckbox" value="email">
                  <label class="form-check-label" for="emailCheckbox">Email</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="phoneNumberCheckbox" value="phone_number">
                  <label class="form-check-label" for="phoneNumberCheckbox">Phone Number</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.user" class="form-check-input" type="checkbox"
                         id="dateOfBirthCheckbox" value="date_of_birth">
                  <label class="form-check-label" for="dateOfBirthCheckbox">Date of Birth</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.customer" class="form-check-input" type="checkbox"
                         id="customerTypeCheckbox" value="type">
                  <label class="form-check-label" for="customerTypeCheckbox">Customer Type</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.customer" class="form-check-input" type="checkbox"
                         id="newsletterSubscriptionCheckbox" value="newsletter_subscription">
                  <label class="form-check-label" for="newsletterSubscriptionCheckbox">Newsletter Subscription</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="streetCheckbox" value="street">
                  <label class="form-check-label" for="streetCheckbox">Street</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="suburbCheckbox" value="suburb">
                  <label class="form-check-label" for="suburbCheckbox">Suburb</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="stateCheckbox" value="state">
                  <label class="form-check-label" for="stateCheckbox">State</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="postCodeCheckbox" value="post_code">
                  <label class="form-check-label" for="postCodeCheckbox">Post Code</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="countryCheckbox" value="country">
                  <label class="form-check-label" for="countryCheckbox">Country</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.address" class="form-check-input" type="checkbox"
                         id="fullAddressCheckbox" value="full_address">
                  <label class="form-check-label" for="fullAddressCheckbox">Full Address</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.service" class="form-check-input" type="checkbox"
                         id="serviceNameCheckbox" value="name">
                  <label class="form-check-label" for="serviceNameCheckbox">Service Name</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="referenceCheckbox" value="reference">
                  <label class="form-check-label" for="referenceCheckbox">Appointment Reference</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="appointmentTypeCheckbox" value="type">
                  <label class="form-check-label" for="appointmentTypeCheckbox">Appointment Type</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="appointmentStatusCheckbox" value="status">
                  <label class="form-check-label" for="appointmentStatusCheckbox">Appointment Status</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="appointmentPlatformCheckbox" value="platform">
                  <label class="form-check-label" for="appointmentPlatformCheckbox">Appointment Platform</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="parkingStatusCheckbox" value="parking">
                  <label class="form-check-label" for="parkingStatusCheckbox">Parking Status</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="preferenceCheckbox" value="preference">
                  <label class="form-check-label" for="preferenceCheckbox">Preference</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="appointmentDateCheckbox" value="date">
                  <label class="form-check-label" for="appointmentDateCheckbox">Date</label>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-2">
                <div class="form-check form-check-inline">
                  <input v-model="exportAppointmentCustomerListParams.selected_columns.appointment" class="form-check-input" type="checkbox"
                         id="appointmentTimeCheckbox" value="time">
                  <label class="form-check-label" for="appointmentTimeCheckbox">Time</label>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end">
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label style="visibility: hidden">Export Data</label>
                  <button :disabled="!(appointments.length && hasSelectedColumns)" type="button" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="exportAppointmentCustomers()">Export Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 25%">Customer</th>
                    <th class="position-relative" style="width: 15%">Service</th>
                    <th class="position-relative" style="width: 10%">Panel</th>
                    <th class="position-relative" style="width: 15%">Job Location</th>
                    <th class="position-relative" style="width: 20%">Technician</th>
                    <th class="position-relative" style="width: 10%">Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(appointment, index) in appointments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span>
                          <i class="bx" :class="appointment.type === 'Home' ? 'bx-home' : 'bxs-business'"></i>
                        </span>
                        <span v-if="appointment.customer && appointment.customer.user">
                          {{ `${appointment.customer.user.first_name} ${appointment.customer.user.last_name}` }}
                        </span>
                      </div>
                      <div>
                        <small v-if="appointment && appointment.reference" class="theme-color">
                          Reference: #{{ appointment.reference }}
                        </small>
                      </div>
                      <div>
                        <small>
                          Service date : {{ `${appointment.date} ${appointment.time}` }}
                        </small>
                      </div>
                      <div>
                        <small>
                          Creation date : {{ appointment.created_at ? appointment.created_at : '-' }}
                        </small>
                      </div>
                    </td>
                    <td>
                      {{ appointment.service && appointment.service.name ? appointment.service.name : '-' }}
                    </td>
                    <td>
                      {{
                        appointment.appointmentCreator && appointment.appointmentCreator.panel ? appointment.appointmentCreator.panel : '-'
                      }}
                    </td>
                    <td>
                      <div v-if="appointment.preference === 'On-Site'">
                        <span v-if="appointment.address">
                          {{
                            `${appointment.address.street}, ${appointment.address.suburb} ${appointment.address.state.toUpperCase()} ${appointment.address.post_code}, ${appointment.address.country}`
                          }}
                        </span>
                      </div>
                      <span v-else class="badge badge-pill">
                        {{ appointment.preference }}
                      </span>
                    </td>
                    <td>
                      <div v-if="appointment?.technicianAppointment?.id">
                        {{
                          `${appointment.technicianAppointment.technician.user.first_name} ${appointment.technicianAppointment.technician.user.last_name}`
                        }}
                      </div>
                      <div v-else>
                        <span class="badge badge-pill badge-danger">To be assign</span>
                      </div>

                    </td>
                    <td>
                      <span class="badge badge-pill"
                            :class="appointmentStatusBadgeColour(appointment.status)">
                        {{ appointment.status }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import { DatePicker } from 'v-calendar';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppointmentList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    DatePicker,
    AppLayout,
  },
  data() {
    return {
      getSettingsParams: {
        type: ["default", "appointment"],
        key: [
          "default_state",
          "appointment_status",
        ],
      },
      getAppointmentsParams: {
        service_id: '',
        preference: '',
        type: '',
        status: '',
        date: [],
        where_has_customer_user_or_customer_address_query: '',
        where_has_franchisee_id: '',
        where_has_address_state: '',
        with_relation: ["customer.user", 'appointmentCreator', "service", "address", "technicianAppointment.technician.user"],
        order_by_date: 'DESC',
      },

      exportAppointmentCustomerListParams: {
        service_id: null,
        preference: null,
        type: null,
        status: null,
        date: [],
        where_has_customer_user_or_customer_address_query: null,
        where_has_franchisee_id: null,
        where_has_address_state: null,
        order_by_date: 'DESC',
        selected_columns: {
          user: [],
          customer: [],
          address: [],
          service: [],
          appointment: [],
        },

      },

      selectedService: {
        value: '',
        name: "Any"
      },

      selectedState: {
        value: '',
        name: "Any",
      },

      selectedPreference: {
        value: '',
        name: "Any",
      },

      selectedAppointmentType: {
        value: '',
        name: "Any",
      },

      selectedStatus: {
        value: '',
        name: "Any",
      },

      customerOrAddressSearch: '',
      selectedFromDate: '',

      selectedToDate: '',

      getServicesParams: {
        order_by_name: 'ASC',
      },
    };
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authFranchisee: 'appAuthLogin/authFranchisee',
      appointments: "appAppointments/appointments",
      services: 'appService/services',
    }),

    preferenceOptions() {
      return [
        {value: '', name: "Any"},
        {value: 0, name: 'On-Site'},
        {value: 1, name: 'Remote'},
      ];
    },

    stateOptions() {
      return [{value: null, name: "Any"}, ...this.$store.getters["appSettings/settingDefaultState"].value];
    },

    appointmentTypeOptions() {
      return [
        {value: '', name: "Any"},
        {value: 0, name: 'Home'},
        {value: 1, name: 'Business'},
      ];
    },

    appointmentStatusOptions() {
      return [{value: '', name: "Any"}, ...this.$store.getters["appSettings/settingAppointmentStatus"].value];
    },

    serviceOptions() {
      let services = this.$store.getters["appService/services"].map(item => {
        return {
          value: item.id,
          name: item.name
        }
      });
      return [{value: null, name: "Any",}, ... services];
    },

    appointmentNoteTypes() {
      return this.$store.getters["appSettings/settingAppointmentNoteType"].value;
    },

    hasSelectedColumns() {
      return this.exportAppointmentCustomerListParams.selected_columns.user.length > 0 ||
          this.exportAppointmentCustomerListParams.selected_columns.customer.length > 0 ||
          this.exportAppointmentCustomerListParams.selected_columns.address.length > 0 ||
          this.exportAppointmentCustomerListParams.selected_columns.service.length > 0 ||
          this.exportAppointmentCustomerListParams.selected_columns.appointment.length > 0;
    }

  },

  watch: {
    selectedService(selectedService) {
      this.getAppointmentsParams.service_id = selectedService.value;
      this.exportAppointmentCustomerListParams.service_id = selectedService.value;
    },

    selectedState(selectedState) {
      this.getAppointmentsParams.where_has_address_state = selectedState.value;
      this.exportAppointmentCustomerListParams.where_has_address_state = selectedState.value;
    },

    selectedPreference(selectedPreference) {
      this.getAppointmentsParams.preference = selectedPreference.value;
      this.exportAppointmentCustomerListParams.preference = selectedPreference.value;
    },

    selectedAppointmentType(selectedAppointmentType) {
      this.getAppointmentsParams.type = selectedAppointmentType.value;
      this.exportAppointmentCustomerListParams.type = selectedAppointmentType.value;
    },

    selectedFromDate(selectedFromDate) {
      this.getAppointmentsParams.date[0] = selectedFromDate;
      this.exportAppointmentCustomerListParams.date[0] = selectedFromDate;
    },

    selectedToDate(selectedToDate) {
      this.getAppointmentsParams.date[1] = selectedToDate;
      this.exportAppointmentCustomerListParams.date[1] = selectedToDate;
    },

    customerOrAddressSearch(customerOrAddressSearch) {
      this.getAppointmentsParams.where_has_customer_user_or_customer_address_query = customerOrAddressSearch;
      this.exportAppointmentCustomerListParams.where_has_customer_user_or_customer_address_query = customerOrAddressSearch;
    },

    selectedStatus(selectedStatus) {
      this.getAppointmentsParams.status = selectedStatus.value;
      this.exportAppointmentCustomerListParams.status = selectedStatus.value;
    },

  },
  methods: {
    ...mapActions({
      getAppointments: "appAppointments/getAppointments",
      postExportAppointmentCustomerList: "appAppointments/postExportAppointmentCustomerList",
      getSettings: "appSettings/getSettings",
      getServices: 'appService/getServices',

    }),

    appointmentStatusBadgeColour(status) {
      if (status === 'Open')
        return 'badge-light-warning';
      else if (status === 'Cancelled')
        return 'badge-light-danger';
      else if (status === 'Closed')
        return 'badge-light-success';
      else if (status === 'Credited')
        return 'badge-light-primary';
      else if (status === 'Delivered')
        return 'badge-light-success';
      else if (status === 'Dispute')
        return 'badge-light-danger';
      else if (status === 'Hold')
        return 'badge-light-danger';
      else if (status === 'Reassigned')
        return 'badge-light-info';
      else if (status === 'Refund')
        return 'badge-light-secondary';
      else if (status === 'Rescheduled')
        return 'badge-light-info';
      else if (status === 'Rescheduled & Reassigned')
        return 'badge-light-info';
      else
        return 'badge-light-secondary';
    },

    async getAppointmentList() {
      this.loader(true);
      await this.getAppointments(this.getAppointmentsParams).then((response) => {
        this.loader(false);
        if (response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async applyAppointmentFilter(pageNumber) {
      this.getAppointmentsParams.page = pageNumber;
      await this.loader(true);
      await this.getAppointmentList();
      await this.loader(false);
    },

    async exportAppointmentCustomers() {
      await this.loader(true);
      await this.postExportAppointmentCustomerList(this.exportAppointmentCustomerListParams).then(async response => {
        await this.loader(false);
        if (response.status === 200) {
          let toastObj = {message: 'Data exported successfully.', type: 'success'};
          await this.showToastMessage(toastObj);
        }

        if (response.message) {
          await this.showToastMessage(response);
        }
      });
    },

    async getServiceList() {
      await this.getServices(this.getServicesParams);
    },

  },
  async mounted() {
    await this.loader(true);
    if (this.authFranchisee.id) {
      this.getAppointmentsParams.where_has_franchisee_id = this.authFranchisee.id;
      this.exportAppointmentCustomerListParams.where_has_franchisee_id = this.authFranchisee.id;
    }
    await this.getSettingList();
    await this.getServiceList();
    await this.loader(false);
  },

};
</script>

<style scoped>
/* this class is for all table drop-down section overflow items to show */
.table-responsive {
  min-height: 80vh;
}

.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

</style>
